/**
 * this file will be imported dozens of times!
 * DO NOT define any css here!
 * only for overriding scss vars and scss mixins!
 */


$primaryTextColor:#ffffff;
$primaryColor:#15ab97;
$contrastColor:#963b7a;
// $primaryColor:#008c9e;
$primaryDarkColor:#3c3282;
// $primaryDarkColor:#015a69;
// $primaryDarkColor:#45656c;
$primaryDarkerColor:#3c3282;
$secondaryColor:#4a6267;
// $greyColor:#4a6267;

$highlightColor:$primaryColor;
$highlightBgColor:$highlightColor;
$highlightTextColor:$primaryTextColor;

$datatableEvenRowBgColor:#fdfafc;
$datatableHoverBgColor:#e3e1e2;


@import 'global/variables';
@import 'global/_mixins';

@mixin appExtrasLightTheme {
	--tabMenuitemBgColor: #{$secondaryColor};
	--trackingConsentPositionBottom: 90px;
}

// $buttonBgColor:transparent;
$buttonBorderRadius:25px;
// $buttonBorder:2px solid #fff;
$buttonTextOnlyPadding:0.8em 1.4em;
$buttonWithLeftIconPadding:0.8em 1.0em;
$buttonWithRightIconPadding: 0.8em 1.0em;
$buttonHoverBgColor:#fff;
$buttonHoverTextColor:$primaryDarkColor;

// @import '//fonts.googleapis.com/css?family=Open+Sans:400';
$fontFamily:"Raleway","Helvetica Neue",Arial, sans-serif;
$headlinesFontFamily:"Raleway","Helvetica Neue",Arial, sans-serif;
// $fontFamily:"Muli","Helvetica Neue",Arial, sans-serif;
// $headlinesFontFamily:"Muli","Helvetica Neue",Arial, sans-serif;
$fontWeight:400;
$headlinesFontWeight:400;
//headers


// $sidebarTopStartBgColor:$secondaryColor;
// $sidebarTopEndBgColor:$secondaryColor;
// $sidebarTopStartBgColor:#00afab;
// $sidebarTopEndBgColor:#267871;
// $sidebarAnchorColor:#ffffff;
$menuitemActiveColor:rgb(36, 41, 40);
$rootMenuitemActiveIconColor:#ffffff;
$menuitemBadgeBgColor:$highlightColor;
$menuitemBadgeTextColor:#333333;


$panelHeaderBgColor:#ddd;
$panelHeaderTextColor:$textColor;
$panelHeaderBorder:0;
$panelHeaderBorderColor:transparent;
$panelFooterBorder:0;
$panelFooterBorderColor:transparent;
$panelFooterBgColor:#ddd;
$panelFooterTextColor:$textColor;

$panelHeaderAltBgColor: $primaryColor;
$panelHeaderAltTextColor: #fff;


$tableHeaderCellBgColor:$secondaryColor;
$tableHeaderCellTextColor:#fff;
$tableHeaderCellHoverBgColor:lighten($secondaryColor,5%);
$tableHeaderCellHoverTextColor:#fff;
$tableBodyRowEvenBgColor:#f0f0f0;
$tableBodyCellBorder:none;
$tableBodyCellBorderColor:transparent;
$tableHeaderCellBorder:none;
$tableHeaderCellBorderColor:transparent;

$paginatorBgColor:$secondaryColor;
$paginatorIconColor:#fff;
$paginatorActiveBgColor: #fff;
$paginatorActiveTextColor: $textColor;

$inputListMinWidth:240px;

$bottombarHeight:70px;
$bottombarMarginTop:0;
